<template>
	<div class="page">
		<div class="toolbar" ref="toolbar">
			<div class="tit"> 编辑场站标签 </div>
			<div class="action-box">
				<el-button @click="save('form')" size="small" icon="el-icon-check" plain
					v-if="$hasAccess('vip.vipcfg/editzk')">保存 </el-button>
			</div>
		</div>
		<el-card class="box-card card">
			<el-form :model="formData" :rules="rules" ref="form" label-width="120px">
				<el-row>
					<el-col :xs="24" :sm="16">
						<div class="el-block">
							<div class="coupon-choose-form">
								<div class="title">
									<el-button class="add-button" icon="el-icon-plus" type="primary" size="small"
										@click="onItemAdd">增加标签</el-button>
								</div>
								<div v-for="idx in itemList.length" class="coupon-item">
									<el-input v-model="itemList[idx-1].label_id" disabled style="width: 200px;"
										placeholder="ID">
									</el-input>
									<el-input v-model="itemList[idx-1].label_name" style="width: 200px;"
										placeholder="标签名">
									</el-input>
									<el-button class="del-button" size="small" type="danger" icon="el-icon-delete"
										v-if="!itemList[idx-1].id" @click="onItemDel(idx)">删除</el-button>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'device-label-list',
		components: {},
		data() {
			return {
				// 表单信息
				formData: {},
				itemList: [],
				// 表单规则
				rules: {},
			}
		},
		mounted() {
			this.init()
		},
		async activated() {
			this.init()
		},
		methods: {
			init() {
				this.getcfg();
			},
			//获取配置
			async getcfg() {
				const params = {
					token: this.$store.state.user.token,
				};
				const res = await this.$api.Device.GetLabelList(params);
				this.itemList = res;
			},
			// 保存
			save(formName) {
				this.$refs[formName].validate(async valid => {
					if (valid) {
						const params = {
							token: this.$store.state.user.token,
							form: JSON.stringify(this.itemList),
						}
						await this.$api.Device.LabelEdit(params)
						this.$notify({
							title: '成功',
							message: '编辑成功',
							type: 'success'
						})
						this.getcfg();
					}
				})
			},
			//增加
			onItemAdd: function() {
				this.itemList.push({
					label_id: '',
					label_name: null,
				});
			},
			//删除
			onItemDel: function(idx) {
				this.itemList.splice(idx - 1, 1);
			},
		}
	}
</script>
<style lang="scss" scoped>
	.coupon-choose-form {
		padding-left: 40px;

		.title {
			.tips {
				font-size: 14px;
				color: #606266;
			}

			.add-button {
				margin-left: 20px;
			}
		}

		.tips {
			font-size: 14px;
			color: #606266;
			padding: 6px 6px;
		}

		.coupon-item {
			display: flex;
			width: 100%;
			padding: 5px 10px;

			.select {
				margin-left: 10px;
				width: 250px;
			}

			.input {
				margin-left: 10px;
				width: 180px;
			}

			.del-button {
				margin-left: 10px;
			}
		}

		.coupon-choose-error-tips {
			margin-left: 20px;
			height: 20px;

			span {
				color: #F56C6C;
				font-size: 14px;
			}
		}
	}
</style>
